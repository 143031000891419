.mpv-page {
    .navbar {
        position: absolute;
        z-index: 100;
        width: 100%;
        background-color: rgba(66, 28, 255, 0.07);
        border-bottom: 1px solid rgba(255, 255, 255, 0.2);
    }
}

.mpv-wrapper {
    background-image: url('/images/mpvBack2.png');
    background-size: cover;
    background-repeat: no-repeat;
    min-height: 700px;

    .title {
        font-size: 48px !important;
        margin-top: 90px;
        margin-bottom: 0px;
    }
}

.mpv-form {
    position: relative;
    background-color: white;
    border-radius: 16px;

    width: 1014px;
    margin: auto;
    margin-top: 44px;
    margin-bottom: 88px;

    padding: 0px 32px;
    padding-right: 12px;

    &__form-col {
        flex: 1;
        border-right: solid 1px #D6D8EA;
        padding-bottom: 32px;
        padding-right: 32px;
    }

    .datetimepicker {
        flex: 1;
    }

    .basic-drop-down .body {
        border-top-left-radius: 16px;
    }

    .text-primary {
        color: #421CFF !important;
    }

    .btn {
        border-radius: 8px;
        font-weight: 700;
        border: none;
        &:hover, &:active, &:focus {
            border: none !important;
            opacity: 1 !important;
            background-color: #3516CC;
            color: white;
        }
    }

    &__search-button {
        height: 56px;
        
        &_disabled {
            background-color: #D6D8EA !important;
            color: #828596 !important;
            pointer-events: none;
        }
    }

    .basic-drop-down {
        padding: 0px;
        position: absolute;
        top: 0;
        left: 0;
    }

    .focused-child {
        background-color: #F8F8FB;
    }

    .dropdown-input {
        background-color: #ECEDF3;
        width: max-content;
        padding: 8px 12px;
        border-top-left-radius: 10px;
        border-bottom-right-radius: 10px;
    }

    .dropdown-input span {
        color: #121212;
        font-size: 16px;
    }

    .dropdown-input .d-icon i {
        color: #121212;
    }

    &__inputs {
        padding-top: 64px;
        padding-bottom: 32px;

        .input-items label {
            color: #828596;
            font-weight: bold;
        }

        .input-group {
            background-color: white;
        }

        .input-control {
            background-color: #F8F8FB !important;
        }

        .input-box {
            background-color: #F8F8FB;
            border: solid 1px #D6D8EA;

            input {
                background-color: #F8F8FB;
                color: rgba(18, 18, 18, 0.8) !important;
            }
        }
    }

   .btn-primary {
        background-color: #421CFF;
        color: white;
   }

   &__partners {
        border-radius: 16px;
        position: relative;
        padding: 32px;
        width: 463px;

        &-spinner {
            position: absolute;
            left: 0;
            right: 0;
            top: 0;
            bottom: 0;
            background-color: rgba(255, 255, 255, 0.5);
            display: flex;
            align-items: center;
            justify-content: center;
        }

        &_result {
            position: relative;
            margin-left: 10px;
            padding-right: 15px;
            min-width: 565px;
        }

        &_result::after {
            content: '';
            pointer-events: none;
            position: absolute;
            top: -10px;
            left: -10px;
            bottom: -10px;
            right: -10px;
            border: 11px solid rgba(66, 28, 255, 0.2);
            outline: 1px solid rgba(255, 255, 255, 0.6);
            border-radius: 16px;
        }

        &-list {
            max-height: 365px;

            &_same {
                max-height: 265px;
            }
        }

        &-title {
            color: #121212;
            font-size: 20px;
            text-align: center;
            margin-bottom: 24px;
            font-weight: 600;
        }

        &-partner {
            position: relative;

            img {
                object-fit: contain;
            }

            &_logo {
                display: flex;
                justify-content: center;
                flex: 50%;
                margin-bottom: 15px;
            }
        }

        .btn {
            width: 166px;
            position: relative;
            right: 0;
            margin-right: 15px;
        }
   }
}

.progress-loading-mpv {
    position: fixed;
    top: 50%;
    left: 50%;
    z-index: 50;
    transform: translate(-50%, -50%);
    background-color: #f8fafc;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;

    img {
        object-fit: contain;
    }

    &__title {
        font-size: 24px;
        font-weight: 700;
        margin-bottom: 32px;
    }

    &__arrow {
        width: 17px;
    }
  
    &__content {
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: column;
      padding: 1rem;
      text-align: center;
      width: 515px;
    }
}