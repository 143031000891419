@import 'variables';
/* purgecss start ignore */

@media (min-width: 1199px) {
	.blog-post-page {
		&-form {
			h3 {
				margin-bottom: -8px;
			}
		}
		#filter-form {
			padding: 0 6px;
		}
	}
	#search-button-container {
		padding-left: 5px;
		padding-right: 5px;
	}
}
@media (min-width: 992px) {
	.navbar-expand-lg .navbar-nav .nav-link {
		padding: 0;
	}

	#contact-us {
		.btn {
			max-width: 256px;
		}
	}
	#search-button {
		&.price-alert-handler-checked {
			font-size: 18px !important;
		}
	}
}

@media (min-width: 1200px) {
	#family-type-options {
		.room {
			display: flex;
		}
	}
}

@media (max-width: 442px) {
	#passthru-p3 {
		.title {
			font-size: 25px;
		}
	}
	#cookie-notice {
		.description {
			font-size: 13px !important;
			line-height: 1.5 !important;
		}

		img {
			width: 16px !important;
			object-fit: contain;
			margin: 0 5px;
		}
	}
	#detail-card {
		.info {
			min-width: unset;
			width: 100%;
			.name,
			.location {
				min-width: unset;
				font-size: 16px;
			}
		}
	}
}

// Large devices (desktops, less than 1200px)
@media (max-width: 1199px) {
	#parent-filter {
		margin-top: 0;
	}
	#kayak-compare-filter-form {
		.input-items {
			.form-control,
			.input-control {
				background: #eee;
			}
		}
	}
	.blog-post-page {
		.input-items {
			.form-control,
			.input-control {
				background: #fff;
			}
		}
	}

	#blog-faq {
		.faq {
			p {
				&.question {
					font-size: 21px;
				}
				&.answer {
					font-size: 18px;
				}
			}
		}
	}
	.input-items {
		.form-control,
		.input-control {
			background: #eeeeee;
		}
	}
	#cookie-notice .description,
	.cookie-confirm-btn {
		font-size: 13px;
	}

	#newsletter-form {
		h4,
		h5 {
			font-size: 24px;
		}
	}
	.main-inputs {
		& > div {
			.input-box {
				margin: 0 !important;
			}
		}
	}
	.input-group {
		background-color: unset !important;
		border-radius: 4px;
	}

	#auth-navbar-nav .nav-link .brief {
		display: none;
	}
	.sign-up-button {
		font-family: $fontFamily, sans-serif;
		font-size: 18px;
		font-weight: 600;
		font-style: normal;
		font-stretch: normal;
		line-height: normal;
		letter-spacing: normal;
		text-align: center;
		color: #fff;
	}
	#search-button-container {
		#search-button {
			min-height: 50px;
			font-size: 22px;
		}
	}
	.curve-mask {
		display: none;
	}

	#slogan-container {
		padding: 50px 0;

		&:before {
			display: none;
		}
	}
	.input-items {
		margin: 6px 0;

		label {
			margin: 4px 0;
		}
	}

	#filter-form {
		.inline-inputs {
			.input {
				width: 100%;
			}
		}
	}
	.input-control:not(.filled) i {
		color: #6d6d6d;
	}
	.basic-drop-down {
		margin-right: 0;

		.body {
			max-width: unset !important;
		}
	}
	#companies {
		margin-top: 25px;
		margin-bottom: 25px;

		ul {
		}
	}

	.timepicker-show {
		justify-content: space-between;
	}
	.cars-filter-form {
		margin-top: 80px;
		padding: 12px !important;
	}
	#search-button {
		box-shadow: unset !important;
	}
}

// Medium devices (tablets, less than 992px)
@media (max-width: 991px) {
	.latest-post {
		&-title {
			h2 {
				font-size: 32px;
				line-height: 34px;
			}
		}
	}
	.blog-post-page {
		#post-main-img {
			height: 360px;
		}
		#post-title {
			font-size: 40px;
			line-height: 42px;
		}
	}
	.input-items {
		.form-control,
		.input-control {
			background: #eeeeee;
		}
	}
	footer #footer-links li,
	footer p {
		font-size: 18px;
	}

	#slogan-container .body .slogan {
		font-size: 30px;
	}
	#progress-loading {
		.body {
			justify-content: flex-start;
			padding-top: 80px;
		}
	}

	#passthru-p3 {
		.title {
			font-size: 45px;
		}
	}
}

// Small devices (landscape phones, less than 768px)
@media (max-width: 767px) {
	#compare-area #filtered-data > div:not(:first-child),
	#compare-area .bottom-info > div:not(:first-child) {
		border-left: unset;
	}

	#compare-area {
		min-height: 100vh;
		&.hotels {
			background: $new;
			color: #fff;
		}
		.comp-logo {
			justify-content: center;
			align-items: center;
			position: relative;
			margin-right: 12px;
		}

		.ml-i {
			margin-left: 0;
		}

		padding: 8px 0px;
		.company-item {
			background: transparent;
			box-shadow: none;
			margin-bottom: 0;
			padding: 0 18px 10px;
			.comp-logo {
				width: 170px;
				background: #fff;
				border-radius: 4px;
				display: flex;
				justify-content: center;
				align-items: center;
				img {
					height: 36px;
					width: 96px !important;
				}
			}
			.view-btn {
				background: $yellow;
				border-radius: 4px;
				padding: 6px 16px;
				font-weight: bold;
				font-style: normal;
				font-size: 14px;
				line-height: 24px;
				color: #000;
				display: flex;
				justify-content: center;
				align-items: center;
			}
			.c-checkbox-compare {
				display: flex;
				input:checked ~ .checkmark {
					background-color: $yellow;
					border-color: $yellow;
				}
				input:checked ~ .checkmark:after {
					border-color: $new;
				}
				.checkmark {
					border-color: #afafaf;
				}
				.form-check {
					top: 5px;
				}
			}
		}
		.c-checkbox-compare {
			position: relative !important;
			top: -2px !important  ;
			left: unset !important;
		}
		.location-info {
			.location-name {
				font-size: 24px !important;
				color: #fff;
				font-weight: 600;
			}
		}
		.start-compare-btn {
			display: block !important;
			border-radius: 16px;
			margin-top: 25px;
		}
		.headline {
			text-align: left;
			font-size: 24px;
			line-height: 32px;
			margin-bottom: 20px;
		}
		#filtered-data {
			align-items: flex-start;
			display: block;
		}
		.underscore {
			font-size: 16px !important;
		}
		.item-name {
			font-size: 12px !important;
			margin-top: 5px !important;
		}
		.item-value {
			font-size: 16px !important;
			margin-bottom: 5px !important;
		}
	}

	#blog-faq {
		.faq {
			p {
				&.question {
					font-size: 18px;
				}
				&.answer {
					font-size: 16px;
				}
			}
			.question-icon {
				font-size: 58.0318px;

				&:before {
					width: 6px;
				}
			}
			.answer-icon {
				font-size: 58.0318px;

				&:before {
					width: 6px;
				}
			}
		}
	}
	#footer-links {
		display: unset !important;
	}

	.input-box .input-control .selected,
	.timepicker-show .selected-time,
	.basic-drop-down .d-name {
		font-size: 15px;
		line-height: 24px;
	}
	.input-items label {
		font-size: 14px;
		line-height: 24px;
		color: #fff;
	}

	.input-items {
		.form-control,
		.input-control {
			background: #eeeeee;
		}
	}

	#passthru-p3 {
		.title {
			font-size: 35px;
		}
	}

	#mobile-input-passengers .body {
		right: 0;
		left: unset;
	}
	footer #footer-links li {
		display: block;
		border-right-width: 0px;
		margin: 16px 0;
	}
	#cookie-notice {
		padding: 6px;

		.description {
			font-size: 15px;
			line-height: 1.7;
		}

		img {
			width: 17px;
			margin: 0 5px;
		}
	}

	#credential_picker_iframe {
		bottom: 65px !important;
	}
}

// Extra small devices (portrait phones, less than 576px)
@media (max-width: 575px) {
	.cars-image {
		width: 100% !important;
	}
	.blog-logo {
		display: none;
	}
	.blog-mail-sign-up {
		&-btn {
			width: 100%;
			margin-top: 8px;
		}
		.form-control {
			height: 52px;
		}
		padding: 32px 32px;
	}
	.blog-post-page {
		#post-main-img {
			height: 200px;
		}
		#post-title {
			font-size: 32px;
			line-height: 36px;
		}
	}
	.blog-topic-page {
		&-img {
			height: 200px;
		}
	}
	.post-list-heading {
		margin-bottom: 24px;
		h3 {
			font-size: 18px;
			font-weight: normal;
		}
	}
	.latest-post {
		&-title {
			h2 {
				font-size: 24px;
				line-height: 26px;
			}
		}
	}
	.post-carousel {
		&-img {
			height: 200px;
		}
		&-title {
			h3 {
				font-size: 32px;
				line-height: 36px;
			}
		}
		&-topic {
			margin-top: 8px;
			margin-bottom: 0;
		}
	}
	.blog-preview {
		margin-bottom: 32px;
		&-topic {
			p {
				margin-top: 8px;
			}
		}
	}
	#main-area .title {
		text-align: left;
	}
	.basic-drop-down .d-name,
	.basic-drop-down .d-icon i {
		color: #000;
	}
	.basic-drop-down {
		& > div {
			background: #eeeeee;
			border-radius: 8px;
			padding: 8px;
			display: inline;
		}
	}
	footer {
		p {
			font-size: 14px;
		}
	}
	#main-content {
		#title {
			font-size: 24px;
		}
		#description {
			font-size: 18px;
			text-align: justify;
		}
	}
	#detail-card {
		text-align: left;
		margin-top: 15px;
		padding: 15px;

		#title {
			text-align: left;
			font-size: 20px;
		}
		.location {
			font-size: 16px;
		}
		.time,
		.price-type {
			font-size: 14px;
		}
	}

	.Logo {
		width: 190px;
		height: auto;
	}

	.select-box {
		margin: 0;
		display: flex;
		justify-content: space-between;
		align-items: center;
		padding-bottom: 5px;

		margin-bottom: 5px;

		.selected-area {
			min-width: 100px;
			min-height: 35px;
		}
	}

	#mobile-input-passengers .body {
		width: calc(200% + 30px);
	}
	.cars-filter-form,
	.cars-filter-form {
		margin-top: 0 !important;
		border-top-left-radius: 0px !important;
		border-top-right-radius: 0px !important;
	}
	#companies {
		ul {
			li {
				width: 145px;

				div img {
					width: 120px;
				}
			}
		}
	}
	#slogan-container .body .slogan {
		font-size: 25px !important;
	}
	#passthru-p3 {
		.title {
			font-size: 25px;
		}
		.logo {
			width: 106px !important;
		}
		.brand-redirection {
			padding: 5px;
			margin-top: 5px;
		}
		#loading-to-partner #animation .m_scroll_arrows {
			width: 12px;
			height: 12px;
		}
	}
}

@media (min-width: 1200px) {
	.top-inputs {
		padding-left: 7px;
	}
	.datetimepicker {
		max-width: 265px;
	}

	.main-inputs {
		div {
			padding: 0;
		}
	}
}

@media (max-width: 360px) {
	.Logo {
		width: 160px;
	}
}
@media (max-width: 320px) {
	.basic-drop-down {
		width: 100%;
		.dropdown-input {
			display: flex;
			justify-content: space-between;
		}
		.d-name {
			span {
				font-size: 13px;
			}
		}
		.body {
			width: 100%;
			.item {
				padding-left: 0;
				font-size: 15px;
			}
		}
	}
}
/* purgecss end ignore */
