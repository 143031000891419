
@font-face {
    font-family: 'fontello';
    src: url('/fonts/icon/fontello.eot?v1.6');
    src: url('/fonts/icon/fontello.eot?v1.6') format('embedded-opentype'),
    url('/fonts/icon/fontello.woff2?v1.6') format('woff2'),
    url('/fonts/icon/fontello.woff?v1.6') format('woff'),
    url('/fonts/icon/fontello.ttf?v1.6') format('truetype'),
    url('/fonts/icon/fontello.svg?v1.6') format('svg');
    font-weight: normal;
    font-display: swap;
    font-style: normal;
}
/* Chrome hack: SVG is rendered more smooth in Windozze. 100% magic, uncomment if you need it. */
/* Note, that will break hinting! In other OS-es font will be not as sharp as it could be */
/*
@media screen and (-webkit-min-device-pixel-ratio:0) {
  @font-face {
    font-family: 'fontello';
    src: url('/fonts/icon/fontello.svg#fontello') format('svg');
  }
}
*/

[class^="icon-"]:before, [class*=" icon-"]:before {
    font-family: "fontello";
    font-style: normal;
    font-weight: normal;
    speak: none;

    display: inline-block;
    text-decoration: inherit;
    width: 1em;
//margin-right: .2em;
    text-align: center;
    /* opacity: .8; */

    /* For safety - reset parent styles, that can break glyph codes*/
    font-variant: normal;
    text-transform: none;

    /* fix buttons height, for twitter bootstrap */
    line-height: 1em;

    /* Animation center compensation - margins should be symmetric */
    /* remove if not needed */
//margin-left: .2em;

    /* you can be more comfortable with increased icons size */
    /* font-size: 120%; */

    /* Font smoothing. That was taken from TWBS */
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;

    /* Uncomment for 3D effect */
    /* text-shadow: 1px 1px 1px rgba(127, 127, 127, 0.3); */
}
.icon-date:before { content: '\21'; } /* '!' */
.icon-departure-copy:before { content: '\22'; } /* '&quot;' */
.icon-departure-copy-2:before { content: '\23'; } /* '#' */
.icon-ships:before { content: '\24'; } /* '$' */
.icon-city:before { content: '\25'; } /* '%' */
.icon-cancel-circled:before { content: '\26'; } /* '&amp;' */
.icon-family:before { content: '\27'; } /* ''' */
.icon-one-person:before { content: '\28'; } /* '(' */
.icon-two-persons:before { content: '\29'; } /* ')' */
.icon-cancel:before { content: '\2a'; } /* '*' */
.icon-cancel-circled2:before { content: '\2b'; } /* '+' */
.icon-user-times:before { content: '\2c'; } /* ',' */
.icon-user-plus:before { content: '\2d'; } /* '-' */
.icon-user:before { content: '\2e'; } /* '.' */
.icon-male:before { content: '\2f'; } /* '/' */
.icon-users:before { content: '\30'; } /* '0' */
.icon-plus:before { content: '\31'; } /* '1' */
.icon-eye-off:before { content: '\32'; } /* '2' */
.icon-eye:before { content: '\33'; } /* '3' */
.icon-lock:before { content: '\34'; } /* '4' */
.icon-lock-open-alt:before { content: '\35'; } /* '5' */
.icon-lock-open:before { content: '\36'; } /* '6' */
.icon-share:before { content: '\37'; } /* '7' */
.icon-share-squared:before { content: '\38'; } /* '8' */
.icon-basics-15-512:before { content: '\39'; } /* '9' */
.icon-plus-icon-transparent-0:before { content: '\3a'; } /* ':' */
.icon-phone:before { content: '\3b'; } /* ';' */
.icon-mail:before { content: '\3c'; } /* '&lt;' */
.icon-flights:before { content: '\e800'; } /* '' */
.icon-arrow:before { content: '\e801'; } /* '' */
.icon-car-7-outline:before { content: '\e802'; } /* '' */
.icon-date-arr:before { content: '\e803'; } /* '' */
.icon-flights-1:before { content: '\e806'; } /* '' */
.icon-hotels:before { content: '\e807'; } /* '' */
.icon-left:before { content: '\e808'; } /* '' */
.icon-fly:before { content: '\e809'; } /* '' */
.icon-switch:before { content: '\e80a'; } /* '' */


