.loading-circles {
    display: flex;
    gap: 15px;

    &__circle {
        width: 20px;
        height: 20px;
        background-color: blue;
        border-radius: 50%;
        animation: bounce 1.5s infinite ease-in-out;
    }
    
    &__circle:nth-child(1) { background-color: #421CFF; animation-delay: 0s; }
    &__circle:nth-child(2) { background-color: #6749ff; animation-delay: 0.2s; }
    &__circle:nth-child(3) { background-color: #a08dff; animation-delay: 0.4s; }
    &__circle:nth-child(4) { background-color: #dad2ff; animation-delay: 0.6s; }
    
    @keyframes bounce {
        0%   { transform: translateY(0px); }
        10%  { transform: translateY(-20px); }
        20%  { transform: translateY(0px); }
        100% { transform: translateY(0px); }
    }
}

