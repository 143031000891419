$midnight: #24234d;
$main: #100225;
$fontFamily: HelveticaNeue;
$navLinkColor: rgba(255, 255, 255, 0.8);
$yellow: #f2c94c;
$black: #333333;
$brownGrey: #9b9b9b;
$aquaBlue: #00d6e6;
$blue: #049dd9;
$blueLight: #09caed;
$blueBlog: #0498da;
$new: #080113;
$light-grey-form-bg: #f6f6f6;
